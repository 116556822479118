import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container/Container';
import Layout from '../components/layout/Layout';
import NewsCard from '../components/newscard/NewsCard';
import SEO from '../components/seo/SEO';
import styles from './Blog.module.scss';

//TODO: Add template to add pagination to this!
export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query NyheterPageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              fluid(maxWidth: 250, maxHeight: 218) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`;

const NyheterPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <Layout>
        <SEO title="News. Much is happening in our field. Read about relevant news from our business." />
        <Container fullPage={true} className="pagePaddingLeft pagePaddingRight">
          <h1 className={styles.welcomeText}>News</h1>
          <div className={styles.grid}>
            {data?.posts?.edges?.map((node: any, index: number) => (
              <NewsCard key={index} {...node.node} section={'news'} />
            ))}
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default NyheterPage;
