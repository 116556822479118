import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { Link } from 'gatsby';
import styles from './NewsCard.module.scss';

interface NewsCardProps {
  _id: string;
  title: string;
  titleLong: string;
  slug: {
    current: string;
  };
  mainImage: {
    asset: {
      fluid: FluidObject;
    };
    alt: string;
  };
  section: string;
}

const NewsCard = (props: NewsCardProps) => {
  return (
    <Link key={props._id} to={`/${props.section}/${props.slug.current}/`} className={styles.card}>
      <div>
        {props.mainImage && props.mainImage.asset && props.mainImage.asset.fluid && (
          <Img
            className={styles.img}
            fluid={props.mainImage.asset.fluid}
            alt={props.mainImage.alt}
          />
        )}
        <div className={styles.content}>
          <p className={styles.title}>{props.title}</p>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
